import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const Anchor = styled.a`
  color: #6d7770;
  margin-top: 5px;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;
// const useGallery = () => {
//   const data = useStaticQuery(graphql`
//     query GalleryImages {
//       images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
//         nodes {
//           Key
//           localFile {
//             childImageSharp {
//               thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
//               full: gatsbyImageData(layout: FULL_WIDTH)
//             }
//           }
//         }
//       }
//     }
//   `);
//   return data.images.nodes.map(node => node.localFile.childImageSharp);
// };

const OtherActivitiesPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <TitleWrapper>
          <Title text={'Other Activities'} />
        </TitleWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <SubTitle>Beaver Creek Village</SubTitle>
          <Content>
            You can find World-Class Shopping and Art Galleries around every corner in Beaver Creek Village. From
            one-of-a-kind artwork to elegant jewelry to the newest skis and snowboards. Check out Gorsuch, North Face
            and The General Store. Stroll and explore St. James Place, Park Plaza, Village Hall and Market Square in
            Beaver Creek Village.
          </Content>
          <SubTitle>Ice Skating</SubTitle>
          <Content>Who knew that you could enjoy the family fun of ice skating in the middle of Beaver Creek?</Content>
          <Content>Black Family Ice Rink 970.845.0438</Content>
          <SubTitle>Nordic Activities</SubTitle>
          <Content>
            Beaver Creek offers a wonderful array of nordic activities for those who wish for an alternative to
            skiing/snowboarding, and the opportunity to take in the breathtaking views from a secluded area called McCoy
            park, Nestled between Beaver Creek and Bachelor Gulch villages. McCoy Park offers spectacular views of 3
            mountain ranges. Activities include Snowshoeing and Cross Country Skiing. Rentals and lessons are available.
            For more information visit{' '}
            <Anchor href="https://www.beavercreek.com/plan-your-trip/ski-and-ride-lessons/category/nordic-center.aspx">
              https://www.beavercreek.com/plan-your-trip/ski-and-ride-lessons/category/nordic-center.aspx
            </Anchor>
          </Content>
          <SubTitle>Snowmobiling</SubTitle>
          <Content>
            This is a fun and adventurous activity with multiple options in the Vail Valley! If you have time, we highly
            recommend Nova Guides at Camp Hale. It is a beautiful and remote mountain location. For more information
            visit{' '}
            <Anchor href="http://www.novaguides.com/colorado-winter-activities/">
              http://www.novaguides.com/colorado-winter-activities/
            </Anchor>
          </Content>

          <SubTitle>Other Activities</SubTitle>
          <Content>
            If you’d like to venture out on your own, here are some other suggestions. You can also visit this link{' '}
            <Anchor href={'https://www.beavercreek.com/explore-the-resort/activities/winter-activities/overview.aspx'}>
              https://www.beavercreek.com/explore-the-resort/activities/winter-activities/overview.aspx
            </Anchor>
          </Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default OtherActivitiesPage;
